import React, { Component , Fragment } from "react";

import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp , FiX , FiMenu, FiCheck } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterThree from "../component/footer/FooterThree";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Uskoro natječaj za ugradnju obnovljivih izvora energije!',
        description: 'Ne propustite natječaj Fond za zaštitu okoliša i energetsku učinkovitost (FZOEU) će 28. rujna 2022. objaviti Javni poziv za energetsku obnovu obiteljskih kuća.',
        buttonText: 'Više...',
        buttonLink: 'https://www.zgradonacelnik.hr/vijesti/fzoeu-evo-kada-ce-se-objaviti-javni-poziv-za-energetsku-obnovu-kuca/859'
    }
]



class HomeParticles extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
   

    render(){
        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
        ];

        const PostList = BlogContent.slice(0 , 5);
        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        return(
            <Fragment>
                <Helmet pageTitle="Zenerg" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo_Zenerg.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo_Zenerg.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','service', 'about', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#start">Start</a></li>
                                    <li><a href="#o_nama">O nama</a></li>
                                    <li><a href="#kontakt">Kontakt</a></li>
                                </Scrollspy>
                                
                            </nav>
                            <div className="header-btn"><a className="btn-default btn-border btn-opacity" target="_blank" href="https://shop.zenerg.hr"><span>WEB SHOP</span></a></div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency with-particles" id="start">
                    <div className="frame-layout__particles">
                        <Particles className="particle"  
                            options={{
                                style:{
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                detectsOn: "canvas",
                                events: {
                                    onClick: {
                                    enable: true,
                                    mode: "push",
                                    },
                                    onHover: {
                                    enable: true,
                                    mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    bubble: {
                                        distance: 100,
                                        duration: 2,
                                        opacity: 0.8,
                                        size: 10,
                                        color: "#888",
                                    },
                                    push: {
                                    quantity: 4,
                                    },
                                    repulse: {
                                        distance: 100,
                                        duration: 0.4,
                                        color: "#888",
                                    },
                                },
                                },
                                particles: {
                                color: {
                                    value: "#888",
                                },
                                links: {
                                    color: "#888",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 4,
                                    straight: false,
                                   
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 2000,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 5,
                                },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                    <div className="im_modern_slider bg_image bg_image--27 ">
                            {SlideList.map((value , index) => (
                                <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description_style-2">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="btn-default btn-large" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="o_nama">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="section-title text-left mb--30">
                                    <span className="subtitle">Zenerg?</span>
                                    <h2 className="title">O nama</h2>
                                    <p className="description">Tvrtka Zenerg osnovana je s ciljem da omogući stručno pristupačno, fer i korektno 
                                     tehničko savjetovanje vezano za obnovljive izvore. Često na tržištu velike i kvalitetne tvrtke nisu 
                                     zainteresirane za manje elektrane i kupci su prisiljeni prihvatiti napuhane cijene ili nekvalificirane izvođače.
                                     Mi smo tim inženjera (FERovci) koji  nudimo tehničko savjetovanje, projektiranje i nabavu opreme po FER cijenama i s certificiranim i provjerenim 
                                     izvođačima te nudimo produženu garanciju na radove. Radimo samo sa ovlaštenim inženjerima elektrotehnike i 
                                     certificiranim instalaterima opreme te vršimo nadzor radova istih. Kupci koji žele samostalno nabaviti opremu, 
                                     samostalno instalirati opremu mogu nas zatražiti za tehničko savjetovanje i nadzor. Da bi usluga bila kompletna 
                                     nabavljamo opremu u vlastitom aranžmanu na veliko i nudimo kupovinu preko našeg <a href="http://shop.zenerg.hr">
                                     WEB SHOP-a </a> gdje možete kupiti robu na rate, s besplatnom dostavom za područje Zagreba, Zagrebačke županije i 
                                     Krapinsko Zagorske županije. {/*Uz to u mogućnosti smo ponuditi savjete vezane za potpunu energetsku neovisnost i nudimo korisnicima 
                                     posjetu takvim već izvedenim objektima*/}
                                     </p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */} 


        


       

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="kontakt">
                    <Contact />
                </div>
                {/* End Contact Us */}


                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                 {/* Start Footer Style  */}
                 <FooterThree />
                {/* End Footer Style  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;