import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';

import { FiChevronUp, FiX , FiMenu, FiCheck } from "react-icons/fi";
import Scrollspy from 'react-scrollspy';



class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo_Zenerg.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo_Zenerg.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','service', 'about', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li><a href="./#start">Start</a></li>
                                    <li><a href="./#o_nama">O nama</a></li>
                                    <li><a href="./#kontakt">Kontakt</a></li>
                                </Scrollspy>
                                
                            </nav>
                            <div className="header-btn"><a className="btn-default btn-border btn-opacity" target="_blank" href="https://shop.zenerg.hr"><span>WEB SHOP</span></a></div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--27"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                                    <h2 className="title theme-gradient">U izradi....</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='eC0HuZ7vWHw' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <div className="section-title">
                                                        <span className="subtitle">.!$#%.</span>
                                                        <h2 className="title">U izradi</h2>
                                                        <p className="description">Detaljan opis i upute u izradi... </p>
                                                        <p className="description">Za detalje nazovite ili pošaljite mail</p>
                                                        <p className="description">Lijevo je snimka sunace elektrane u Sisku instalirane od našeg certificiranog partnera tvrtke Elektroluč solar.</p>
                                                    </div>
                                                    <ul className="liststyle mt--30">
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

            

            </React.Fragment>
        )
    }
}
export default ServiceDetails;