import React ,{ Component }from "react";
import { FiSun , FiPenTool , FiPercent , FiDollarSign, FiShoppingCart, FiSmile } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiSun />,
        title: 'Projektiranje solarnih elektrana',
        description: 'Obilazak lokacije, prikupljanje dokumentacije,  izrada projekta, HEP suglasnost, prijava na natječaj, izvođenje, nadzor, prikupljanje dokumentacije za puštanje u rad, puštanje u pogon... '
    },
    {
        icon: <FiPenTool />,
        title: 'Tehničko savjetovanje kod samogradnje i nabavke opreme ',
        description: 'Ukoliko želite sami ugraditi elektranu, postaviti konstrukciju, panele, nabaviti jeftinije opremu tu smo da vam pomognemo... '
    },
    {
        icon: <FiShoppingCart />,
        title: 'Prodaja elektrotehnicke opreme vezane za obnovljive izvore energije i OFF GRID sustavi',
        description: 'Solarni paneli, inverteri, punjači za automobile, kabeli, sitni pribor, konstrukcije, vjetroelektrane, dizalice topline i kompletni OFF GRID sustavi'
    },
    { 
        icon: <FiPercent />,
        title: 'Energetska ucinkovitost',
        description: 'Ukoliko želite smanjiti režije vršimo energetski pregled te na temelju podataka nudimo usluge: zamjene stare rasvjete LED rasvjetom, zamjena kompresora, starih kotlova, izolacija cijevi, ugradnja dizalica topline, solarnih panela... te edukacija i neke "besplatne" promjene koje pridonose smanjenju potoršnje energije'
    },
    { 
        icon: <FiSmile />,
        title: 'Totalna energetska neovisnost',
        description: 'Ukoliko imate želje ili potrebe za totalnom energetskom neovisnošću moguće je osmisliti off grid sustave - bunari i ultraljubičasto pročišćavanje vode, solarni paneli, dizalice topline, biopročistači otpadnih voda...'
    },

]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={"/service-details-"+i}>
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
