// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';

// Home layout

import HomeParticles from './home/HomeParticles';

// Dark Home Layout 
// Element Layout
import Service from "./elements/Service";
import Projektiranje from "./elements/ServiceDetails";
import TehnickoSavjetovanje from "./elements/ServiceDetails";
import Prodaja from "./elements/ServiceDetails";
import EnergetskaUcinkovitost from "./elements/ServiceDetails";
import TotalnaEnergetskaNeovisnost from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeParticles}/>
                     

                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details-0`} component={Projektiranje}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details-1`} component={TehnickoSavjetovanje}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details-2`} component={Prodaja}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details-3`} component={EnergetskaUcinkovitost}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details-4`} component={TotalnaEnergetskaNeovisnost}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();