import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/sma.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/fronius.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/solvis.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/sungrow.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/trina.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/solaredge.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/aesolar.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/victron.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/elektroluc.png" alt="Logo Images"/>
                    </li>

                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;